@keyframes loader-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; } }

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0; } }

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #fff;
  color: #080808;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400; }
  body .app-container {
    padding-top: 44px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden; }
    body .app-container.no-scroll {
      overflow: hidden; }
      body .app-container.no-scroll.scrollbar-margin {
        margin-right: 17px; }
    body .app-container .app {
      height: 100%; }
      body .app-container .app .app-header {
        z-index: 10;
        width: 100%;
        top: 0;
        left: 0;
        position: fixed;
        background: #080808;
        color: #fff;
        box-shadow: 0px 4px 4px #00000022;
        -webkit-box-shadow: 0px 4px 4px #00000022;
        -moz-box-shadow: 0px 4px 4px #00000022; }
        body .app-container .app .app-header .app-header-inner {
          display: flex;
          flex-direction: row;
          align-items: center; }
          body .app-container .app .app-header .app-header-inner .app-menu .app-menu-icon {
            padding: 8px 0;
            height: 20px;
            width: 20px; }
            body .app-container .app .app-header .app-header-inner .app-menu .app-menu-icon path {
              fill: #fff; }
            body .app-container .app .app-header .app-header-inner .app-menu .app-menu-icon:hover path {
              fill: #ff0052; }
          body .app-container .app .app-header .app-header-inner .app-menu .context-menu-content {
            top: 100%; }
          body .app-container .app .app-header .app-header-inner .profile-menu .username {
            margin: 0;
            padding: 8px 4px;
            background-color: #efefef;
            border-bottom: 1px solid #c8c8c8;
            text-overflow: ellipsis;
            overflow: hidden; }
          body .app-container .app .app-header .app-header-inner .app-logo {
            margin-left: 8px;
            height: 24px;
            padding: 8px 0; }
          body .app-container .app .app-header .app-header-inner .app-title {
            font-size: 1.5em;
            margin: 0; }
          body .app-container .app .app-header .app-header-inner .notification-button {
            margin-right: 8px;
            padding: 8px; }
          body .app-container .app .app-header .app-header-inner .user-avatar {
            transition: transform 0.1s; }
            body .app-container .app .app-header .app-header-inner .user-avatar:hover {
              transform: rotate(15deg); }
      body .app-container .app .app-footer {
        background: #080808;
        padding: 4px 0;
        color: #fff;
        box-shadow: 0px -4px 4px #00000022;
        -webkit-box-shadow: 0px -4px 4px #00000022;
        -moz-box-shadow: 0px -4px 4px #00000022; }
        body .app-container .app .app-footer .app-footer-inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: right; }
          body .app-container .app .app-footer .app-footer-inner .app-logo {
            height: 32px; }
          body .app-container .app .app-footer .app-footer-inner h6 {
            text-align: right; }
      body .app-container .app .comic-list {
        position: relative; }
        body .app-container .app .comic-list .comic-list-upper {
          margin: 0 auto;
          margin-bottom: 16px;
          display: flex;
          flex-direction: column;
          align-items: center; }
          @media (min-width: 768px) {
            body .app-container .app .comic-list .comic-list-upper {
              flex-direction: row; } }
          body .app-container .app .comic-list .comic-list-upper .comic-list-title {
            flex: 1;
            margin-bottom: 8px; }
            @media (min-width: 768px) {
              body .app-container .app .comic-list .comic-list-upper .comic-list-title {
                margin-bottom: 0; } }
          body .app-container .app .comic-list .comic-list-upper .comic-list-filters {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center; }
            body .app-container .app .comic-list .comic-list-upper .comic-list-filters:first-child {
              margin-top: 0; }
            body .app-container .app .comic-list .comic-list-upper .comic-list-filters > *:not(:first-child) {
              margin-left: 8px; }
            body .app-container .app .comic-list .comic-list-upper .comic-list-filters .anonymous-switch .checkmark {
              top: 6px; }
            body .app-container .app .comic-list .comic-list-upper .comic-list-filters .anonymous-switch .checkbox-label {
              width: 110px; }
        body .app-container .app .comic-list .comic-list-inner {
          display: flex;
          flex-direction: column;
          align-items: center; }
          body .app-container .app .comic-list .comic-list-inner .comic {
            margin-bottom: 32px; }
          body .app-container .app .comic-list .comic-list-inner .comic-list-bottom {
            min-height: 40px;
            position: relative; }
        body .app-container .app .comic-list .tip-strip {
          margin-top: 128px; }
      body .app-container .app .comic-vote {
        display: flex;
        flex-direction: row;
        align-items: center; }
        body .app-container .app .comic-vote .rating {
          text-align: center;
          width: 30px;
          color: #969696;
          margin: 0; }
      body .app-container .app .comic {
        position: relative;
        overflow: visible;
        -webkit-animation: fade-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: fade-in 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
        body .app-container .app .comic .comic-upper {
          font-size: 10px;
          color: #969696; }
          body .app-container .app .comic .comic-upper p {
            margin: 0;
            margin-bottom: 1px; }
        body .app-container .app .comic .comic-content {
          cursor: pointer;
          position: relative;
          display: inline-block;
          border: 1px solid #c8c8c8;
          background-color: #fff;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          box-shadow: 6px 6px 6px #00000016;
          -webkit-box-shadow: 6px 6px 6px #00000016;
          -moz-box-shadow: 6px 6px 6px #00000016; }
          body .app-container .app .comic .comic-content.for-image-capture {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0; }
        body .app-container .app .comic .comic-lower {
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: stretch; }
          body .app-container .app .comic .comic-lower > * {
            flex: 1; }
          body .app-container .app .comic .comic-lower .comic-share-button .button-label {
            display: none; }
            @media (min-width: 768px) {
              body .app-container .app .comic .comic-lower .comic-share-button .button-label {
                display: flex; } }
          body .app-container .app .comic .comic-lower .comic-lower-inner {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 8px 0; }
            body .app-container .app .comic .comic-lower .comic-lower-inner > *:not(:first-child) {
              margin-left: 4px; }
            body .app-container .app .comic .comic-lower .comic-lower-inner .comic-lower-details {
              color: #777777; }
              body .app-container .app .comic .comic-lower .comic-lower-inner .comic-lower-details > * {
                margin: 0; }
          body .app-container .app .comic .comic-lower .comment-thread {
            margin-bottom: 16px; }
            body .app-container .app .comic .comic-lower .comment-thread .comments {
              max-height: 300px;
              border-top: 1px solid #c8c8c8;
              border-bottom: 1px solid #c8c8c8; }
          body .app-container .app .comic .comic-lower:empty {
            display: none; }
      body .app-container .app .comic-panel {
        position: relative;
        border: 1px solid #c8c8c8; }
        body .app-container .app .comic-panel > img {
          width: 100%;
          height: 100%; }
        body .app-container .app .comic-panel .dialogue {
          position: absolute;
          background-color: transparent;
          display: flex;
          flex-direction: column;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px; }
          body .app-container .app .comic-panel .dialogue.text-colour-black > * {
            color: #080808; }
          body .app-container .app .comic-panel .dialogue.text-colour-white > * {
            color: #fff; }
          body .app-container .app .comic-panel .dialogue.censored > * {
            color: #c8c8c8; }
          body .app-container .app .comic-panel .dialogue.edit-empty {
            cursor: text;
            border: 1px solid #969696; }
          body .app-container .app .comic-panel .dialogue > * {
            max-height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            border: none;
            font-family: Arial, Helvetica, sans-serif;
            overflow: hidden;
            background-color: transparent;
            overflow-wrap: break-word;
            white-space: pre-line; }
          body .app-container .app .comic-panel .dialogue div {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            letter-spacing: -0.022em; }
          body .app-container .app .comic-panel .dialogue textarea {
            resize: none; }
          body .app-container .app .comic-panel .dialogue.text-align-horizontal-middle > * {
            text-align: center; }
          body .app-container .app .comic-panel .dialogue.text-align-horizontal-left > * {
            text-align: left; }
          body .app-container .app .comic-panel .dialogue.text-align-horizontal-right > * {
            text-align: right; }
          body .app-container .app .comic-panel .dialogue.text-align-vertical-top {
            justify-content: flex-start; }
          body .app-container .app .comic-panel .dialogue.text-align-vertical-bottom {
            justify-content: flex-end; }
          body .app-container .app .comic-panel .dialogue.text-align-vertical-middle {
            justify-content: center; }
        body .app-container .app .comic-panel .comic-panel-subtle {
          font-family: Arial, Helvetica, sans-serif;
          position: absolute;
          color: #c8c8c8;
          background-color: #fff; }
          body .app-container .app .comic-panel .comic-panel-subtle.comic-panel-id {
            bottom: 0;
            left: 0; }
          body .app-container .app .comic-panel .comic-panel-subtle.comic-panel-author {
            bottom: 0;
            right: 0;
            padding: 0.04em 0.08em; }
            body .app-container .app .comic-panel .comic-panel-subtle.comic-panel-author.me {
              background-color: #ff0052;
              color: #fff;
              font-weight: bold; }
        body .app-container .app .comic-panel.editing {
          cursor: pointer; }
      body .app-container .app .comic-panel-pair {
        display: flex;
        flex-direction: column;
        align-items: center; }

@media screen and (orientation: landscape) and (min-width: 576px) {
  body .app-container .app .comic-panel-pair {
    flex-direction: row;
    justify-content: center; } }

@media screen and (orientation: portrait) and (min-width: 768px) {
  body .app-container .app .comic-panel-pair {
    flex-direction: row;
    justify-content: center; } }
      body .app-container .app .form-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch; }
        body .app-container .app .form-wrapper.auth-form {
          box-shadow: 6px 6px 6px #00000016;
          -webkit-box-shadow: 6px 6px 6px #00000016;
          -moz-box-shadow: 6px 6px 6px #00000016;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          background-color: #fff;
          border: 1px solid #c8c8c8;
          padding: 16px;
          margin: 0 auto;
          align-self: stretch;
          max-width: 320px; }
          @media (min-width: 576px) {
            body .app-container .app .form-wrapper.auth-form {
              width: 100%; } }
          body .app-container .app .form-wrapper.auth-form h2 {
            text-align: center;
            margin-bottom: 8px; }
          body .app-container .app .form-wrapper.auth-form .button-container {
            justify-content: center; }
        body .app-container .app .form-wrapper.flat-form .button-container {
          justify-content: center; }
          @media (min-width: 576px) {
            body .app-container .app .form-wrapper.flat-form .button-container {
              justify-content: flex-start; } }
        body .app-container .app .form-wrapper form {
          display: flex;
          flex-direction: column;
          align-items: stretch; }
          body .app-container .app .form-wrapper form > *:not(:first-child) {
            margin-top: 8px; }
          body .app-container .app .form-wrapper form .field-container {
            display: flex;
            flex-direction: column;
            align-items: stretch; }
            body .app-container .app .form-wrapper form .field-container > * {
              text-align: left; }
            body .app-container .app .form-wrapper form .field-container > label {
              font-size: 12px;
              margin-bottom: 4px; }
              body .app-container .app .form-wrapper form .field-container > label .optional {
                color: #777777; }
            body .app-container .app .form-wrapper form .field-container.error input:not([type=checkbox]):not([type=radio]):not([type=file]) {
              border-color: #f02100; }
            body .app-container .app .form-wrapper form .field-container.error textarea, body .app-container .app .form-wrapper form .field-container.error select {
              border-color: #f02100; }
            body .app-container .app .form-wrapper form .field-container textarea {
              min-height: 60px;
              resize: vertical; }
            body .app-container .app .form-wrapper form .field-container .checkbox {
              margin: 8px 0; }
          body .app-container .app .form-wrapper form .button-container {
            margin-top: 8px; }
            @media (min-width: 576px) {
              body .app-container .app .form-wrapper form .button-container {
                margin-top: 16px; } }
            body .app-container .app .form-wrapper form .button-container:not(:last-child) {
              margin-bottom: 8px; }
              @media (min-width: 576px) {
                body .app-container .app .form-wrapper form .button-container:not(:last-child) {
                  margin-bottom: 16px; } }
        body .app-container .app .form-wrapper .field-error {
          margin-top: 4px;
          color: #f02100;
          font-size: 12px;
          text-align: left; }
          body .app-container .app .form-wrapper .field-error.overall-error {
            text-align: center; }
        body .app-container .app .form-wrapper .form-message {
          color: #777777;
          text-align: center;
          font-size: 12px;
          margin: 8px 0; }
          body .app-container .app .form-wrapper .form-message + .form-message {
            margin-top: 0; }
      body .app-container .app .button {
        background: none;
        cursor: pointer;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        text-decoration: none;
        border: 1px solid;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: inline-flex;
        align-items: center;
        flex-direction: row; }
        body .app-container .app .button:focus {
          outline: 0; }
        body .app-container .app .button:hover {
          text-decoration: none; }
        body .app-container .app .button .button-label {
          pointer-events: none; }
          body .app-container .app .button .button-label:not(:first-child) {
            margin-left: 8px; }
          body .app-container .app .button .button-label:not(:last-child) {
            margin-right: 8px; }
        body .app-container .app .button .button-icon {
          pointer-events: none; }
        body .app-container .app .button.button-grey.button-hollow {
          color: #777777;
          border-color: #777777;
          background-color: transparent; }
          body .app-container .app .button.button-grey.button-hollow .button-icon path {
            fill: #777777; }
          body .app-container .app .button.button-grey.button-hollow:hover {
            border-color: #5e5e5e;
            color: #5e5e5e; }
            body .app-container .app .button.button-grey.button-hollow:hover .button-icon path {
              fill: #5e5e5e; }
        body .app-container .app .button.button-grey.button-solid {
          color: #fff;
          border-color: #777777;
          background: #777777; }
          body .app-container .app .button.button-grey.button-solid .button-icon path {
            fill: #fff; }
          body .app-container .app .button.button-grey.button-solid:hover {
            border-color: #868686;
            background: #868686; }
        body .app-container .app .button.button-black.button-hollow {
          color: #080808;
          border-color: #080808;
          background-color: transparent; }
          body .app-container .app .button.button-black.button-hollow .button-icon path {
            fill: #080808; }
          body .app-container .app .button.button-black.button-hollow:hover {
            border-color: black;
            color: black; }
            body .app-container .app .button.button-black.button-hollow:hover .button-icon path {
              fill: black; }
        body .app-container .app .button.button-black.button-solid {
          color: #fff;
          border-color: #080808;
          background: #080808; }
          body .app-container .app .button.button-black.button-solid .button-icon path {
            fill: #fff; }
          body .app-container .app .button.button-black.button-solid:hover {
            border-color: #171717;
            background: #171717; }
        body .app-container .app .button.button-green.button-hollow {
          color: #48AD92;
          border-color: #48AD92;
          background-color: transparent; }
          body .app-container .app .button.button-green.button-hollow .button-icon path {
            fill: #48AD92; }
          body .app-container .app .button.button-green.button-hollow:hover {
            border-color: #398974;
            color: #398974; }
            body .app-container .app .button.button-green.button-hollow:hover .button-icon path {
              fill: #398974; }
        body .app-container .app .button.button-green.button-solid {
          color: #fff;
          border-color: #48AD92;
          background: #48AD92; }
          body .app-container .app .button.button-green.button-solid .button-icon path {
            fill: #fff; }
          body .app-container .app .button.button-green.button-solid:hover {
            border-color: #59baa0;
            background: #59baa0; }
        body .app-container .app .button.button-red.button-hollow {
          color: #f02100;
          border-color: #f02100;
          background-color: transparent; }
          body .app-container .app .button.button-red.button-hollow .button-icon path {
            fill: #f02100; }
          body .app-container .app .button.button-red.button-hollow:hover {
            border-color: #bd1a00;
            color: #bd1a00; }
            body .app-container .app .button.button-red.button-hollow:hover .button-icon path {
              fill: #bd1a00; }
        body .app-container .app .button.button-red.button-solid {
          color: #fff;
          border-color: #f02100;
          background: #f02100; }
          body .app-container .app .button.button-red.button-solid .button-icon path {
            fill: #fff; }
          body .app-container .app .button.button-red.button-solid:hover {
            border-color: #ff3110;
            background: #ff3110; }
        body .app-container .app .button.button-pink.button-hollow {
          color: #ff0052;
          border-color: #ff0052;
          background-color: transparent; }
          body .app-container .app .button.button-pink.button-hollow .button-icon path {
            fill: #ff0052; }
          body .app-container .app .button.button-pink.button-hollow:hover {
            border-color: #cc0042;
            color: #cc0042; }
            body .app-container .app .button.button-pink.button-hollow:hover .button-icon path {
              fill: #cc0042; }
        body .app-container .app .button.button-pink.button-solid {
          color: #fff;
          border-color: #ff0052;
          background: #ff0052; }
          body .app-container .app .button.button-pink.button-solid .button-icon path {
            fill: #fff; }
          body .app-container .app .button.button-pink.button-solid:hover {
            border-color: #ff1f67;
            background: #ff1f67; }
        body .app-container .app .button.button-transparent {
          border: none; }
          body .app-container .app .button.button-transparent.button-hollow {
            color: #525252;
            border-color: #525252;
            background-color: transparent; }
            body .app-container .app .button.button-transparent.button-hollow .button-icon path {
              fill: #525252; }
            body .app-container .app .button.button-transparent.button-hollow:hover {
              border-color: #393939;
              color: #393939; }
              body .app-container .app .button.button-transparent.button-hollow:hover .button-icon path {
                fill: #393939; }
          body .app-container .app .button.button-transparent.button-solid {
            color: #fff;
            border-color: #525252;
            background: #525252; }
            body .app-container .app .button.button-transparent.button-solid .button-icon path {
              fill: #fff; }
            body .app-container .app .button.button-transparent.button-solid:hover {
              border-color: #616161;
              background: #616161; }
        body .app-container .app .button.button-white.button-hollow {
          color: #fff;
          border-color: #fff;
          background-color: transparent; }
          body .app-container .app .button.button-white.button-hollow .button-icon path {
            fill: #fff; }
          body .app-container .app .button.button-white.button-hollow:hover {
            border-color: #e6e6e6;
            color: #e6e6e6; }
            body .app-container .app .button.button-white.button-hollow:hover .button-icon path {
              fill: #e6e6e6; }
        body .app-container .app .button.button-white.button-solid {
          color: #fff;
          border-color: #fff;
          background: #fff; }
          body .app-container .app .button.button-white.button-solid .button-icon path {
            fill: #fff; }
          body .app-container .app .button.button-white.button-solid:hover {
            border-color: white;
            background: white; }
        body .app-container .app .button.button-white.button-hollow:hover {
          border-color: #ff0052;
          color: #ff0052; }
          body .app-container .app .button.button-white.button-hollow:hover .button-icon path {
            fill: #ff0052; }
        body .app-container .app .button.button-sm {
          padding: 4px 8px;
          font-size: 14px; }
          body .app-container .app .button.button-sm .button-icon {
            height: 14px;
            width: 14px; }
        body .app-container .app .button.button-md {
          padding: 8px 16px;
          font-size: 16px; }
          body .app-container .app .button.button-md .button-icon {
            height: 16px;
            width: 16px; }
        body .app-container .app .button.button-lg {
          padding: 16px 32px;
          font-size: 18px; }
          body .app-container .app .button.button-lg .button-icon {
            height: 18px;
            width: 18px; }
        body .app-container .app .button.button-xl {
          padding: 16px 32px;
          font-size: 24px; }
          body .app-container .app .button.button-xl .button-icon {
            height: 24px;
            width: 24px; }
      body .app-container .app .modal {
        width: 94vw;
        background-color: #fff; }
        body .app-container .app .modal .modal-header {
          background-color: #080808;
          color: #fff;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 8px; }
          body .app-container .app .modal .modal-header .modal-title {
            color: #fff; }
          body .app-container .app .modal .modal-header .modal-icon {
            height: 16px;
            width: 16px;
            margin-right: 8px; }
            body .app-container .app .modal .modal-header .modal-icon path {
              fill: #fff; }
          body .app-container .app .modal .modal-header .modal-close-icon {
            cursor: pointer;
            height: 16px;
            width: 16px;
            padding: 4px 0; }
            body .app-container .app .modal .modal-header .modal-close-icon path {
              fill: #fff; }
            body .app-container .app .modal .modal-header .modal-close-icon:hover path {
              fill: #ff0052; }
        body .app-container .app .modal .modal-content {
          padding: 8px; }
        body .app-container .app .modal.modal-confirm {
          max-width: 400px; }
          body .app-container .app .modal.modal-confirm .button-container {
            margin-top: 16px;
            justify-content: flex-end; }
        body .app-container .app .modal.modal-alert {
          max-width: 300px; }
          body .app-container .app .modal.modal-alert .button-container {
            margin-top: 16px;
            justify-content: center; }
        body .app-container .app .modal.modal-share-comic {
          max-width: 360px; }
          body .app-container .app .modal.modal-share-comic .share-comic-container {
            display: flex;
            flex-direction: column;
            align-items: center; }
            body .app-container .app .modal.modal-share-comic .share-comic-container > *:not(:first-child) {
              margin-top: 8px; }
            body .app-container .app .modal.modal-share-comic .share-comic-container .input-link {
              max-width: 240px; }
            body .app-container .app .modal.modal-share-comic .share-comic-container .comic-canvas-holder {
              display: flex;
              justify-content: center;
              height: 25vh;
              width: 100%; }
              body .app-container .app .modal.modal-share-comic .share-comic-container .comic-canvas-holder:empty {
                height: 0; }
              body .app-container .app .modal.modal-share-comic .share-comic-container .comic-canvas-holder canvas {
                width: auto !important;
                height: auto !important;
                max-height: 100%;
                max-width: 100%; }
        body .app-container .app .modal.modal-report-comic-panel {
          max-width: 440px;
          font-size: 14px; }
          body .app-container .app .modal.modal-report-comic-panel .report-requirements {
            margin-bottom: 16px; }
          body .app-container .app .modal.modal-report-comic-panel .button-container {
            justify-content: flex-end; }
      body .app-container .app .dropdown.blank-value:not(:focus) {
        color: #777777; }
      body .app-container .app .template-navigation {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 500px;
        margin: 0 auto; }
        body .app-container .app .template-navigation .template-info h2 {
          color: #ff0052; }
        body .app-container .app .template-navigation .template-info h1, body .app-container .app .template-navigation .template-info h2, body .app-container .app .template-navigation .template-info h3, body .app-container .app .template-navigation .template-info h4, body .app-container .app .template-navigation .template-info h5, body .app-container .app .template-navigation .template-info h6 {
          text-align: center; }
        body .app-container .app .template-navigation .button-container {
          flex: 1; }
          body .app-container .app .template-navigation .button-container:last-child {
            justify-content: flex-end; }
          body .app-container .app .template-navigation .button-container .button .button-label {
            display: none; }
            @media (min-width: 576px) {
              body .app-container .app .template-navigation .button-container .button .button-label {
                display: initial; } }
      body .app-container .app .template-panel-carousel {
        position: relative;
        display: inline-block;
        border: 1px solid #c8c8c8;
        background-color: #fff;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        box-shadow: 6px 6px 6px #00000016;
        -webkit-box-shadow: 6px 6px 6px #00000016;
        -moz-box-shadow: 6px 6px 6px #00000016;
        border-width: 2px; }
        body .app-container .app .template-panel-carousel .template-panel-carousel-slider .slick-list {
          width: 100%; }
          body .app-container .app .template-panel-carousel .template-panel-carousel-slider .slick-list .template-panel-item {
            position: relative; }
            body .app-container .app .template-panel-carousel .template-panel-carousel-slider .slick-list .template-panel-item .comic-panel {
              cursor: pointer;
              border: none; }
        body .app-container .app .template-panel-carousel .lil-buddy {
          z-index: 1;
          position: absolute;
          bottom: 100%;
          right: 0;
          height: 56px;
          margin-bottom: -6px; }
          @media (min-width: 768px) {
            body .app-container .app .template-panel-carousel .lil-buddy {
              height: 138px;
              margin-bottom: -18px;
              right: 30px; } }
      body .app-container .app .progress-bar {
        height: 14px;
        width: 100%;
        background-color: #969696;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        overflow: hidden;
        position: relative; }
        body .app-container .app .progress-bar .progress-bar-inner {
          height: 100%;
          transition: width 0.2s;
          background-color: #525252; }
        body .app-container .app .progress-bar .progress-bar-label {
          text-transform: uppercase;
          line-height: 14px;
          font-size: 10px;
          color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          text-align: center;
          font-weight: bold; }
      body .app-container .app .checkbox .checkbox-inner {
        padding-left: 18px;
        font-size: 14px; }
        body .app-container .app .checkbox .checkbox-inner .checkmark {
          height: 18px;
          width: 18px;
          font-size: 18px;
          line-height: 18px; }
          body .app-container .app .checkbox .checkbox-inner .checkmark .knob {
            height: calc(18px - 4px);
            width: calc(18px - 4px);
            top: 2px;
            left: 2px; }
      body .app-container .app .checkbox.checked .checkbox-inner .checkmark .knob {
        height: calc(18px - 4px);
        width: calc(18px - 4px);
        left: calc(18px - calc(18px - 4px) - 2px); }
      @media (min-width: 576px) {
        body .app-container .app .checkbox .checkbox-inner {
          padding-left: 20px;
          font-size: 16px; }
          body .app-container .app .checkbox .checkbox-inner .checkmark {
            height: 20px;
            width: 20px;
            font-size: 20px;
            line-height: 20px; }
            body .app-container .app .checkbox .checkbox-inner .checkmark .knob {
              height: calc(20px - 4px);
              width: calc(20px - 4px);
              top: 2px;
              left: 2px; }
        body .app-container .app .checkbox.checked .checkbox-inner .checkmark .knob {
          height: calc(20px - 4px);
          width: calc(20px - 4px);
          left: calc(20px - calc(20px - 4px) - 2px); } }
      body .app-container .app .checkbox .checkbox-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        cursor: pointer;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
        body .app-container .app .checkbox .checkbox-inner input {
          cursor: pointer;
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0; }
        body .app-container .app .checkbox .checkbox-inner .checkbox-label {
          font-size: 12px;
          margin-top: 4px;
          margin-left: 8px; }
        body .app-container .app .checkbox .checkbox-inner .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #fff;
          border: 1px solid #c8c8c8;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          font-weight: bold;
          text-align: center; }
      body .app-container .app .checkbox:hover .checkmark {
        background-color: #efefef; }
      body .app-container .app .checkbox.checked .checkmark {
        background-color: #ff0052;
        color: #fff; }
      body .app-container .app .checkbox.checked:hover .checkmark {
        background-color: #ff1f67; }
      body .app-container .app .checkbox.switch .checkbox-inner {
        padding-left: 30px;
        font-size: 16px; }
        body .app-container .app .checkbox.switch .checkbox-inner .checkmark {
          height: 20px;
          width: 30px;
          font-size: 20px;
          line-height: 20px; }
          body .app-container .app .checkbox.switch .checkbox-inner .checkmark .knob {
            height: calc(20px - 4px);
            width: calc(20px - 4px);
            top: 2px;
            left: 2px; }
      body .app-container .app .checkbox.switch.checked .checkbox-inner .checkmark .knob {
        height: calc(20px - 4px);
        width: calc(20px - 4px);
        left: calc(30px - calc(20px - 4px) - 2px); }
      body .app-container .app .checkbox.switch .checkbox-inner .checkmark {
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        overflow: hidden;
        background-color: #969696;
        border: none; }
        body .app-container .app .checkbox.switch .checkbox-inner .checkmark .knob {
          background-color: #fff;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          position: absolute;
          transition: left 0.2s; }
      body .app-container .app .checkbox.switch.checked .checkbox-inner .checkmark {
        background-color: #ff0052; }
      body .app-container .app .context-menu {
        position: relative;
        cursor: pointer; }
        body .app-container .app .context-menu .context-menu-content {
          color: #080808;
          cursor: default;
          position: absolute;
          z-index: 10;
          background-color: #fff;
          border: 1px solid #c8c8c8;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          width: 150px;
          display: none;
          box-shadow: 4px 4px 4px #00000022;
          -webkit-box-shadow: 4px 4px 4px #00000022;
          -moz-box-shadow: 4px 4px 4px #00000022; }
          body .app-container .app .context-menu .context-menu-content.open {
            display: flex;
            flex-direction: column; }
          body .app-container .app .context-menu .context-menu-content.left {
            left: 0; }
          body .app-container .app .context-menu .context-menu-content.right {
            right: 0; }
          body .app-container .app .context-menu .context-menu-content.top {
            bottom: calc(100% + 4px); }
          body .app-container .app .context-menu .context-menu-content.bottom {
            top: calc(100% + 4px); }
          body .app-container .app .context-menu .context-menu-content .menu-items {
            display: flex;
            flex-direction: column; }
            body .app-container .app .context-menu .context-menu-content .menu-items .menu-item {
              padding: 16px;
              color: #080808;
              text-align: left;
              font-size: 14px;
              cursor: pointer; }
              body .app-container .app .context-menu .context-menu-content .menu-items .menu-item .menu-item-inner {
                display: flex;
                flex-direction: row; }
                body .app-container .app .context-menu .context-menu-content .menu-items .menu-item .menu-item-inner svg {
                  height: 1em;
                  margin-right: 8px; }
              body .app-container .app .context-menu .context-menu-content .menu-items .menu-item:not(:first-child) {
                border-top: 1px solid #c8c8c8; }
              body .app-container .app .context-menu .context-menu-content .menu-items .menu-item:hover {
                color: #ff0052;
                text-decoration: none;
                background-color: #ffedf3; }
                body .app-container .app .context-menu .context-menu-content .menu-items .menu-item:hover svg path {
                  fill: #ff0052; }
      body .app-container .app .timer {
        text-align: center; }
        body .app-container .app .timer.urgent {
          color: #f02100; }
      body .app-container .app .notification-menu {
        position: relative; }
        body .app-container .app .notification-menu .context-menu-content {
          width: 200px; }
        body .app-container .app .notification-menu .notification-content {
          font-size: 12px;
          max-height: 300px;
          overflow-y: auto; }
          body .app-container .app .notification-menu .notification-content .notification {
            padding: 8px;
            position: relative; }
            body .app-container .app .notification-menu .notification-content .notification.actionable {
              background-color: #ffedf3; }
            body .app-container .app .notification-menu .notification-content .notification .message {
              overflow-wrap: break-word;
              white-space: pre-line; }
            body .app-container .app .notification-menu .notification-content .notification > p {
              margin: 0; }
              body .app-container .app .notification-menu .notification-content .notification > p:not(:first-child) {
                margin-top: 8px; }
            body .app-container .app .notification-menu .notification-content .notification .title {
              font-weight: bold; }
            body .app-container .app .notification-menu .notification-content .notification .link {
              position: absolute;
              height: 100%;
              width: 100%;
              left: 0;
              top: 0; }
            body .app-container .app .notification-menu .notification-content .notification.linked:hover {
              color: #ff0052; }
            body .app-container .app .notification-menu .notification-content .notification:not(.linked) {
              color: #777777; }
            body .app-container .app .notification-menu .notification-content .notification:not(:first-child) {
              border-top: 1px solid #c8c8c8; }
          body .app-container .app .notification-menu .notification-content > .empty-text {
            padding: 16px; }
      body .app-container .app .avatar-selector {
        min-height: 40px;
        position: relative;
        position: relative; }
        body .app-container .app .avatar-selector > * {
          margin: 0 auto; }
        body .app-container .app .avatar-selector .avatar-settings {
          margin: 16px 0; }
          body .app-container .app .avatar-selector .avatar-settings .avatar-setting {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 8px; }
            body .app-container .app .avatar-selector .avatar-settings .avatar-setting > * {
              margin: 0 8px; }
            body .app-container .app .avatar-selector .avatar-settings .avatar-setting .setting-label {
              width: 80px; }
        body .app-container .app .avatar-selector .or {
          margin: 16px 0;
          text-align: center; }
        body .app-container .app .avatar-selector .button-container {
          margin-top: 8px; }
      body .app-container .app .comment-thread .comments {
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 8px;
        padding-bottom: 8px; }
        body .app-container .app .comment-thread .comments .comment {
          margin: 8px 0; }
          body .app-container .app .comment-thread .comments .comment:not(:first-child) {
            padding-top: 8px; }
        body .app-container .app .comment-thread .comments .posting-message {
          margin: 8px; }
      body .app-container .app .comment-thread .comment-input-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 8px; }
        body .app-container .app .comment-thread .comment-input-container .user-avatar {
          margin-right: 8px; }
      body .app-container .app .comment-input {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative; }
        body .app-container .app .comment-input textarea {
          align-self: stretch;
          resize: vertical; }
        body .app-container .app .comment-input .button-container {
          margin-top: 4px; }
      body .app-container .app .comment {
        display: flex;
        flex-direction: row;
        align-items: flex-start; }
        body .app-container .app .comment .user-avatar, body .app-container .app .comment .empty-avatar {
          margin-right: 8px; }
        body .app-container .app .comment .empty-avatar {
          width: 32px; }
        body .app-container .app .comment .comment-inner {
          flex: 1; }
          body .app-container .app .comment .comment-inner .comment-upper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 8px; }
            body .app-container .app .comment .comment-inner .comment-upper .user-date {
              font-size: 12px;
              color: #969696; }
          body .app-container .app .comment .comment-inner .comment-lower .comment-value {
            font-size: 14px;
            overflow-wrap: break-word;
            white-space: pre-line; }
          body .app-container .app .comment .comment-inner .comment-lower .comment-actions {
            margin-top: 8px; }
            body .app-container .app .comment .comment-inner .comment-lower .comment-actions:empty {
              display: none; }
            body .app-container .app .comment .comment-inner .comment-lower .comment-actions a {
              font-size: 12px;
              color: #777777; }
              body .app-container .app .comment .comment-inner .comment-lower .comment-actions a:hover {
                text-decoration: underline; }
              body .app-container .app .comment .comment-inner .comment-lower .comment-actions a:not(:first-child) {
                margin-left: 8px; }
      body .app-container .app .share-buttons {
        display: flex;
        flex-direction: row; }
        body .app-container .app .share-buttons .share-button {
          width: 24px;
          height: 24px; }
          body .app-container .app .share-buttons .share-button:not(:first-child) {
            margin-left: 8px; }
          body .app-container .app .share-buttons .share-button > * path {
            fill: #080808; }
          body .app-container .app .share-buttons .share-button > *:hover path {
            fill: #ff0052; }
      body .app-container .app .tabbed-panels {
        box-shadow: 6px 6px 6px #00000016;
        -webkit-box-shadow: 6px 6px 6px #00000016;
        -moz-box-shadow: 6px 6px 6px #00000016;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        background-color: #fff;
        border: 1px solid #c8c8c8; }
        body .app-container .app .tabbed-panels .tabbed-panels-tabs {
          display: flex;
          flex-direction: row;
          padding: 0;
          background-color: #c8c8c8;
          list-style: none;
          margin: 0; }
          body .app-container .app .tabbed-panels .tabbed-panels-tabs .tabbed-panels-tab {
            flex: 1;
            text-align: center;
            cursor: pointer;
            outline: 0;
            color: #080808;
            padding: 8px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; }
            body .app-container .app .tabbed-panels .tabbed-panels-tabs .tabbed-panels-tab .tab-title {
              font-size: 12px;
              text-align: center; }
            @media (min-width: 576px) {
              body .app-container .app .tabbed-panels .tabbed-panels-tabs .tabbed-panels-tab {
                padding: 8px 16px; }
                body .app-container .app .tabbed-panels .tabbed-panels-tabs .tabbed-panels-tab .tab-title {
                  font-size: 14px; } }
            body .app-container .app .tabbed-panels .tabbed-panels-tabs .tabbed-panels-tab.react-tabs__tab--selected {
              background-color: #fff; }
            body .app-container .app .tabbed-panels .tabbed-panels-tabs .tabbed-panels-tab:not(.react-tabs__tab--selected) {
              background-color: #c8c8c8; }
              body .app-container .app .tabbed-panels .tabbed-panels-tabs .tabbed-panels-tab:not(.react-tabs__tab--selected):hover {
                background-color: #969696; }
        body .app-container .app .tabbed-panels .tabbed-panels-panel {
          background-color: #fff;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px; }
          body .app-container .app .tabbed-panels .tabbed-panels-panel.react-tabs__tab-panel--selected {
            min-height: 40px;
            position: relative;
            padding: 16px; }
      body .app-container .app .achievement-list .table-title {
        margin-bottom: 16px;
        text-align: center; }
      body .app-container .app .achievement-list .achievement-table {
        margin: 0 auto;
        width: 100%;
        max-width: 420px; }
        body .app-container .app .achievement-list .achievement-table .achievement-row td {
          vertical-align: top;
          padding: 8px 0; }
        body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-image {
          padding-right: 8px; }
          body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-image.unlocked .achievement-image {
            background-color: #ff0052; }
          body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-image:not(.unlocked) {
            opacity: 0.5; }
        body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail {
          width: 100%; }
          body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail h1, body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail h2, body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail h3, body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail h4, body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail h5, body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail h6 {
            text-align: left; }
          body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail p {
            margin: 0; }
          body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail .progress-bar {
            margin-top: 4px;
            height: 10px; }
            body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail .progress-bar .progress-bar-inner {
              background-color: #ff0052; }
            body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail .progress-bar .progress-bar-label {
              line-height: 10px; }
          body .app-container .app .achievement-list .achievement-table .achievement-row .td-achievement-detail .unlocked-info {
            font-size: 10px;
            font-weight: bold;
            color: #969696;
            margin-top: 4px; }
      body .app-container .app .tip-strip .tip-strip-inner {
        display: flex;
        align-items: center;
        flex-direction: column; }
        @media (min-width: 576px) {
          body .app-container .app .tip-strip .tip-strip-inner {
            flex-direction: row;
            justify-content: space-evenly; }
            body .app-container .app .tip-strip .tip-strip-inner > * {
              width: 50%; } }
        body .app-container .app .tip-strip .tip-strip-inner .tip-detail {
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 200px;
          text-align: center; }
          @media (min-width: 576px) {
            body .app-container .app .tip-strip .tip-strip-inner .tip-detail {
              align-items: flex-start;
              max-width: none;
              text-align: left; } }
        body .app-container .app .tip-strip .tip-strip-inner .tip-image {
          max-width: 200px; }
          body .app-container .app .tip-strip .tip-strip-inner .tip-image.vertical {
            margin-bottom: 16px; }
          body .app-container .app .tip-strip .tip-strip-inner .tip-image.horizontal {
            display: none; }
          @media (min-width: 576px) {
            body .app-container .app .tip-strip .tip-strip-inner .tip-image.vertical {
              display: none; }
            body .app-container .app .tip-strip .tip-strip-inner .tip-image.horizontal {
              display: block; } }
      body .app-container .app .group-list .group-list-upper {
        margin: 0 auto;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        @media (min-width: 768px) {
          body .app-container .app .group-list .group-list-upper {
            flex-direction: row; } }
        body .app-container .app .group-list .group-list-upper .group-list-title {
          flex: 1;
          margin-bottom: 8px; }
          @media (min-width: 768px) {
            body .app-container .app .group-list .group-list-upper .group-list-title {
              margin-bottom: 0; } }
        body .app-container .app .group-list .group-list-upper .group-list-filters {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center; }
          body .app-container .app .group-list .group-list-upper .group-list-filters:first-child {
            margin-top: 0; }
          body .app-container .app .group-list .group-list-upper .group-list-filters > *:not(:first-child) {
            margin-left: 8px; }
      body .app-container .app .group-list .group-list-inner .group-list-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 16px; }
        body .app-container .app .group-list .group-list-inner .group-list-item .group-details {
          flex: 1;
          margin-left: 8px; }
          body .app-container .app .group-list .group-list-inner .group-list-item .group-details .group-name {
            font-weight: bold;
            flex: 1;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            margin-bottom: 4px; }
          body .app-container .app .group-list .group-list-inner .group-list-item .group-details .group-subtitle {
            margin-top: 4px;
            margin-bottom: 4px; }
          body .app-container .app .group-list .group-list-inner .group-list-item .group-details .description {
            color: #777777;
            overflow-wrap: break-word;
            white-space: pre-line;
            font-size: 12px; }
      body .app-container .app .group-list .group-list-bottom {
        min-height: 40px;
        position: relative; }
      body .app-container .app .group-avatar {
        display: block;
        overflow: hidden;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px; }
      body .app-container .app .image-upload input {
        display: none; }
      body .app-container .app .image-upload .error {
        margin-top: 4px;
        color: #f02100;
        font-size: 12px;
        text-align: center; }
      body .app-container .app .user-avatar {
        display: block;
        overflow: hidden;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%; }
      body .app-container .app .play-info {
        color: #525252;
        margin-top: 8px;
        text-align: center; }
        body .app-container .app .play-info > p {
          margin: 0; }
        body .app-container .app .play-info .start-new-info {
          margin-top: 8px; }
      body .app-container .app .stats-summary:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #efefef; }
      body .app-container .app .stats-summary > *:not(:last-child) {
        padding-bottom: 8px; }
      body .app-container .app .stats-summary > *:not(:first-child) {
        padding-top: 8px; }
      body .app-container .app .stats-summary .stats-row {
        display: flex;
        flex-direction: row; }
        body .app-container .app .stats-summary .stats-row .stat {
          flex: 1; }
      body .app-container .app .stats-summary .stat h1, body .app-container .app .stats-summary .stat h2, body .app-container .app .stats-summary .stat h3, body .app-container .app .stats-summary .stat h4, body .app-container .app .stats-summary .stat h5, body .app-container .app .stats-summary .stat h6 {
        text-align: center; }
      body .app-container .app .stats-summary .stat h5 {
        color: #525252; }
      body .app-container .app .stats-summary .stat > *:not(:first-child) {
        margin-top: 8px; }
      body .app-container .app .button-input {
        display: flex;
        flex-direction: row; }
        body .app-container .app .button-input input {
          flex: 1;
          min-width: 100px;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          border-right: none; }
        body .app-container .app .button-input .button {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
      body .app-container .app .search {
        position: relative;
        flex: 10;
        max-width: 200px;
        display: flex; }
        body .app-container .app .search input:not([type=checkbox]):not([type=radio]):not([type=file]) {
          border-radius: 6px;
          background-color: #232323;
          border: none;
          color: #fff;
          margin: 0 8px;
          width: 100%;
          max-width: 100%; }
        body .app-container .app .search textarea, body .app-container .app .search select {
          border-radius: 6px;
          background-color: #232323;
          border: none;
          color: #fff;
          margin: 0 8px;
          width: 100%;
          max-width: 100%; }
        body .app-container .app .search .search-results {
          position: absolute;
          font-size: 1.5em;
          border-radius: 6px;
          top: 100%;
          left: 0;
          width: 100%;
          z-index: 10;
          background: #fff;
          color: #080808;
          font-size: 0.8em;
          display: flex;
          flex-direction: column;
          border: 1px solid #c8c8c8;
          box-shadow: 0px 4px 4px #00000022;
          -webkit-box-shadow: 0px 4px 4px #00000022;
          -moz-box-shadow: 0px 4px 4px #00000022; }
          body .app-container .app .search .search-results .empty-text {
            padding: 16px 0;
            overflow: hidden; }
          body .app-container .app .search .search-results .search-result {
            padding: 4px;
            display: flex;
            flex-direction: row;
            text-decoration: none; }
            body .app-container .app .search .search-results .search-result:not(:first-child) {
              border-top: 1px solid #c8c8c8; }
            body .app-container .app .search .search-results .search-result .search-result-detail {
              display: flex;
              flex-direction: column;
              margin-left: 4px;
              overflow: hidden; }
              body .app-container .app .search .search-results .search-result .search-result-detail h1, body .app-container .app .search .search-results .search-result .search-result-detail h2, body .app-container .app .search .search-results .search-result .search-result-detail h3, body .app-container .app .search .search-results .search-result .search-result-detail h4, body .app-container .app .search .search-results .search-result .search-result-detail h5, body .app-container .app .search .search-results .search-result .search-result-detail h6 {
                text-align: left; }
              body .app-container .app .search .search-results .search-result .search-result-detail .search-result-title {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap; }
              body .app-container .app .search .search-results .search-result .search-result-detail .search-result-subtitle {
                color: #525252; }
            body .app-container .app .search .search-results .search-result .empty-image {
              min-width: 32px;
              min-height: 32px; }
            body .app-container .app .search .search-results .search-result:hover {
              background-color: #efefef; }
      body .app-container .app .group-pending-info-group .group-pending-info:not(:last-child), body .app-container .app .group-pending-info-group .invite-bar:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #efefef; }
      body .app-container .app .group-pending-info-group .invite-bar .button-input {
        max-width: 300px; }
      body .app-container .app .group-pending-info-group .group-pending-info-inner {
        min-height: 40px;
        position: relative; }
        body .app-container .app .group-pending-info-group .group-pending-info-inner .group-pending-info .group-pending-info-heading {
          text-align: left;
          margin-bottom: 16px; }
        body .app-container .app .group-pending-info-group .group-pending-info-inner .group-pending-info .group-pending-info-item {
          display: flex;
          flex-direction: row;
          align-items: flex-start; }
          body .app-container .app .group-pending-info-group .group-pending-info-inner .group-pending-info .group-pending-info-item:not(:last-child) {
            margin-bottom: 16px; }
          body .app-container .app .group-pending-info-group .group-pending-info-inner .group-pending-info .group-pending-info-item .item-details {
            flex: 1;
            margin: 0 8px; }
            body .app-container .app .group-pending-info-group .group-pending-info-inner .group-pending-info .group-pending-info-item .item-details .item-name {
              margin-bottom: 4px;
              font-weight: bold; }
            body .app-container .app .group-pending-info-group .group-pending-info-inner .group-pending-info .group-pending-info-item .item-details .item-subtitle {
              margin: 0; }
        body .app-container .app .group-pending-info-group .group-pending-info-inner .empty-text {
          text-align: left; }
      body .app-container .app .group-pending-info-user > *:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #efefef; }
      body .app-container .app .group-pending-info-user .group-pending-info .group-pending-info-heading {
        text-align: left;
        margin-bottom: 16px; }
      body .app-container .app .group-pending-info-user .group-pending-info .group-pending-info-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start; }
        body .app-container .app .group-pending-info-user .group-pending-info .group-pending-info-item:not(:last-child) {
          margin-bottom: 16px; }
        body .app-container .app .group-pending-info-user .group-pending-info .group-pending-info-item .item-details {
          flex: 1;
          margin: 0 8px; }
          body .app-container .app .group-pending-info-user .group-pending-info .group-pending-info-item .item-details .item-name {
            margin-bottom: 4px;
            font-weight: bold; }
          body .app-container .app .group-pending-info-user .group-pending-info .group-pending-info-item .item-details .item-subtitle {
            margin: 0; }
      body .app-container .app .group-pending-info-user .group-pending-info .empty-text {
        text-align: left; }
      body .app-container .app .play-button {
        display: flex;
        flex-direction: column;
        align-items: center; }
        body .app-container .app .play-button .play-button-button {
          display: flex;
          flex-direction: column;
          max-width: 160px;
          padding: 8px 16px;
          min-width: 80px; }
          body .app-container .app .play-button .play-button-button .play-button-title:not(:last-child) {
            margin-bottom: 4px; }
          body .app-container .app .play-button .play-button-button .play-button-title:last-child {
            padding: 8px 16px; }
          body .app-container .app .play-button .play-button-button .play-button-sub {
            font-size: 10px;
            margin: 0;
            text-align: center; }
            body .app-container .app .play-button .play-button-button .play-button-sub:not(:last-child) {
              margin-bottom: 2px; }
        body .app-container .app .play-button .play-button-clear {
          margin: 0;
          margin-top: 8px;
          margin-bottom: 16px;
          text-align: center;
          font-size: 14px;
          display: inline-block; }
      body .app-container .app .comic-info-label p {
        font-size: 12px;
        line-height: 18px;
        margin: 0; }
        body .app-container .app .comic-info-label p:not(:last-child) {
          margin-bottom: 8px; }
      body .app-container .app .page-comic .page-comic-inner {
        margin: 0 auto;
        display: flex;
        flex-direction: column; }
        body .app-container .app .page-comic .page-comic-inner .comic-area {
          min-height: 40px;
          position: relative; }
          body .app-container .app .page-comic .page-comic-inner .comic-area .comic-info-label {
            margin-bottom: 16px;
            text-align: center; }
            @media (min-width: 576px) {
              body .app-container .app .page-comic .page-comic-inner .comic-area .comic-info-label {
                text-align: left; } }
          body .app-container .app .page-comic .page-comic-inner .comic-area .progress-bar {
            margin: 0 auto;
            width: 240px; }
          body .app-container .app .page-comic .page-comic-inner .comic-area .comic-wrapper {
            margin-bottom: 64px; }
          body .app-container .app .page-comic .page-comic-inner .comic-area .comic-list {
            margin-top: 64px; }
      body .app-container .app .page-home .home-inner {
        display: flex;
        flex-direction: column; }
        @media (min-width: 768px) {
          body .app-container .app .page-home .home-inner {
            flex-direction: row; } }
        body .app-container .app .page-home .home-inner .home-header {
          display: flex;
          flex-direction: column;
          align-items: center; }
          body .app-container .app .page-home .home-inner .home-header .app-logo {
            width: 120px;
            height: 60px; }
            @media (min-width: 768px) {
              body .app-container .app .page-home .home-inner .home-header .app-logo {
                width: 200px;
                height: 100px; } }
          body .app-container .app .page-home .home-inner .home-header .page-subtitle {
            text-align: center; }
        @media (min-width: 768px) {
          body .app-container .app .page-home .home-inner > .home-header {
            display: none; } }
        body .app-container .app .page-home .home-inner .home-detail {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: stretch; }
          @media (min-width: 768px) {
            body .app-container .app .page-home .home-inner .home-detail {
              margin-right: 16px; } }
          body .app-container .app .page-home .home-inner .home-detail > .home-header {
            display: none; }
            @media (min-width: 768px) {
              body .app-container .app .page-home .home-inner .home-detail > .home-header {
                display: flex; } }
          body .app-container .app .page-home .home-inner .home-detail .template-panel-carousel {
            margin-top: 16px; }
            @media (min-width: 768px) {
              body .app-container .app .page-home .home-inner .home-detail .template-panel-carousel {
                display: none; } }
            body .app-container .app .page-home .home-inner .home-detail .template-panel-carousel .lil-buddy {
              display: none; }
          body .app-container .app .page-home .home-inner .home-detail hr {
            margin: 16px 0; }
          body .app-container .app .page-home .home-inner .home-detail .play-panel {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 16px; }
            body .app-container .app .page-home .home-inner .home-detail .play-panel .play-info {
              margin-top: 8px; }
            body .app-container .app .page-home .home-inner .home-detail .play-panel .play-button {
              margin-bottom: 8px; }
            body .app-container .app .page-home .home-inner .home-detail .play-panel .progress-bar {
              width: 200px;
              margin: 0 auto;
              max-width: 220px; }
              body .app-container .app .page-home .home-inner .home-detail .play-panel .progress-bar .progress-bar-inner {
                background-color: #ff0052; }
        body .app-container .app .page-home .home-inner .home-feature {
          flex-direction: column;
          position: relative;
          justify-content: flex-end;
          display: none; }
          @media (min-width: 768px) {
            body .app-container .app .page-home .home-inner .home-feature {
              display: flex; } }
          body .app-container .app .page-home .home-inner .home-feature > * {
            align-self: center;
            margin-top: 16px; }
            @media (min-width: 768px) {
              body .app-container .app .page-home .home-inner .home-feature > * {
                margin-top: 0; } }
          body .app-container .app .page-home .home-inner .home-feature .template-panel-carousel.with-lil-buddy {
            margin-top: 100px; }
      body .app-container .app .page-play .play-area {
        min-height: 40px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center; }
        body .app-container .app .page-play .play-area .play-area-top {
          display: flex;
          flex-direction: column;
          align-items: center; }
          body .app-container .app .page-play .play-area .play-area-top .timer {
            text-align: center; }
          body .app-container .app .page-play .play-area .play-area-top .instruction {
            margin: 0;
            margin-top: 4px;
            font-size: 12px;
            max-width: 300px; }
            body .app-container .app .page-play .play-area .play-area-top .instruction .play-tag {
              font-weight: bold;
              background: #c8c8c8;
              margin: 1px 0;
              padding: 0 2px;
              border-radius: 2px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              display: inline-block; }
          body .app-container .app .page-play .play-area .play-area-top .progress-bar {
            margin: 8px 0;
            width: 240px; }
            body .app-container .app .page-play .play-area .play-area-top .progress-bar.with-dialogue .progress-bar-inner {
              background-color: #ff0052; }
        body .app-container .app .page-play .play-area .comic-panel-pair {
          border: 1px solid #c8c8c8;
          background-color: #fff;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          box-shadow: 6px 6px 6px #00000016;
          -webkit-box-shadow: 6px 6px 6px #00000016;
          -moz-box-shadow: 6px 6px 6px #00000016; }
        body .app-container .app .page-play .play-area .play-actions {
          margin: 16px 0; }
        body .app-container .app .page-play .play-area .play-options {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 32px 0; }
        body .app-container .app .page-play .play-area .start-help {
          margin-top: 64px;
          color: #969696; }
          body .app-container .app .page-play .play-area .start-help ul {
            text-align: center;
            list-style-type: none;
            padding: 0;
            font-size: 14px; }
        body .app-container .app .page-play .play-area .end-buttons {
          flex-direction: column; }
          body .app-container .app .page-play .play-area .end-buttons > * {
            margin-top: 16px; }
        body .app-container .app .page-play .play-area .anon-message {
          background-color: #232323;
          max-width: 400px;
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          color: #fff;
          padding: 8px 16px; }
          body .app-container .app .page-play .play-area .anon-message h1, body .app-container .app .page-play .play-area .anon-message h2, body .app-container .app .page-play .play-area .anon-message h3, body .app-container .app .page-play .play-area .anon-message h4, body .app-container .app .page-play .play-area .anon-message h5, body .app-container .app .page-play .play-area .anon-message h6 {
            color: #fff; }
          @media (min-width: 576px) {
            body .app-container .app .page-play .play-area .anon-message {
              padding: 16px; } }
          body .app-container .app .page-play .play-area .anon-message .button {
            margin-top: 8px; }
          body .app-container .app .page-play .play-area .anon-message ul {
            text-align: left;
            font-size: 12px;
            margin-top: 0;
            padding-left: 8px; }
        body .app-container .app .page-play .play-area .tip-strip {
          margin-top: 256px; }
      body .app-container .app .page-group .page-group-inner {
        min-height: 40px;
        position: relative; }
        body .app-container .app .page-group .page-group-inner .group-info .group-info-header > * {
          text-align: center; }
        body .app-container .app .page-group .page-group-inner .group-info .group-info-header .group-avatar {
          margin: 0 auto;
          margin-bottom: 8px; }
        body .app-container .app .page-group .page-group-inner .group-info .group-info-header .created-date {
          margin: 4px 0;
          color: #525252; }
        body .app-container .app .page-group .page-group-inner .group-info .group-info-header .group-call-to-action {
          margin: 16px 0; }
          body .app-container .app .page-group .page-group-inner .group-info .group-info-header .group-call-to-action .join-info {
            color: #ff0052; }
        body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels {
          margin-top: 16px; }
          body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .group-details .group-description {
            border-bottom: 1px solid #efefef;
            overflow-wrap: break-word;
            white-space: pre-line;
            font-size: 14px;
            padding-bottom: 16px; }
          body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .group-details .group-comments > .empty-text {
            margin-top: 16px; }
          body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .group-details .group-comments .comment-thread .comments {
            max-height: 400px;
            border-bottom: 1px solid #efefef; }
          body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .members .group-pending-info-group {
            margin-bottom: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid #efefef; }
          body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .members .members-heading {
            margin-bottom: 16px;
            text-align: left; }
          body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .members .member-list {
            display: flex;
            flex-direction: column; }
            body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .members .member-list .member-list-item {
              display: flex;
              flex-direction: row; }
              body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .members .member-list .member-list-item:not(:last-child) {
                margin-bottom: 16px; }
              body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .members .member-list .member-list-item .member-list-item-detail {
                margin-left: 8px;
                flex: 1; }
                body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .members .member-list .member-list-item .member-list-item-detail .username {
                  text-align: left;
                  margin-bottom: 4px; }
                body .app-container .app .page-group .page-group-inner .group-info .tabbed-panels .members .member-list .member-list-item .member-list-item-detail .joined-at {
                  margin: 0; }
        body .app-container .app .page-group .page-group-inner .group-info .challenges .group-challenges-list {
          border-top: 1px solid #efefef;
          padding-top: 16px;
          margin-top: 16px; }
          body .app-container .app .page-group .page-group-inner .group-info .challenges .group-challenges-list .group-challenge-item {
            display: flex;
            flex-direction: row;
            align-items: flex-start; }
            body .app-container .app .page-group .page-group-inner .group-info .challenges .group-challenges-list .group-challenge-item:not(:last-child) {
              margin-bottom: 16px;
              padding-bottom: 16px;
              border-bottom: 1px solid #efefef; }
            body .app-container .app .page-group .page-group-inner .group-info .challenges .group-challenges-list .group-challenge-item .challenge-detail {
              flex: 1; }
              body .app-container .app .page-group .page-group-inner .group-info .challenges .group-challenges-list .group-challenge-item .challenge-detail .challenge {
                font-size: 14px;
                margin-bottom: 8px;
                font-weight: bold;
                text-align: center; }
        body .app-container .app .page-group .page-group-inner .comic-list {
          margin-top: 32px; }
      body .app-container .app .page-group-editor .group-avatar-editor {
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-items: center; }
      body .app-container .app .page-group-editor .group-editor {
        min-height: 40px;
        position: relative; }
      body .app-container .app .page-templates .templates {
        box-shadow: 6px 6px 6px #00000016;
        -webkit-box-shadow: 6px 6px 6px #00000016;
        -moz-box-shadow: 6px 6px 6px #00000016;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        background-color: #fff;
        border: 1px solid #c8c8c8;
        padding: 16px; }
        body .app-container .app .page-templates .templates ul {
          padding-left: 0;
          list-style-type: none; }
          body .app-container .app .page-templates .templates ul li:not(:last-child) {
            margin-bottom: 16px; }
      body .app-container .app .page-template .template-navigation {
        margin: 0 auto; }
      body .app-container .app .page-template .template-name {
        text-align: center;
        margin-top: 16px; }
      body .app-container .app .page-template .top-comic-container {
        margin-top: 16px;
        min-height: 40px;
        position: relative; }
        body .app-container .app .page-template .top-comic-container h3 {
          margin-bottom: 16px; }
      body .app-container .app .page-template .comic-list {
        margin-top: 32px; }
      body .app-container .app .page-leaderboards .leaderboards-inner {
        min-height: 40px;
        position: relative; }
        body .app-container .app .page-leaderboards .leaderboards-inner .tabbed-panels-panel {
          padding: 0; }
          body .app-container .app .page-leaderboards .leaderboards-inner .tabbed-panels-panel .table-info {
            padding: 8px 16px;
            margin: 0;
            text-align: center; }
        body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table {
          border-collapse: collapse;
          width: 100%; }
          body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item {
            text-align: left; }
            body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item td {
              margin: 0 16px; }
            body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item:not(:last-child) {
              border-bottom: 1px solid #efefef; }
            body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item h1, body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item h2, body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item h3, body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item h4, body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item h5, body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item h6 {
              text-align: left; }
            body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item.leaderboard-user td, body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item.leaderboard-group td {
              align-items: center; }
            body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item.leaderboard-highlight {
              background-color: #efefef; }
            body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item .leaderboard-item-detail > *:not(:first-child) {
              margin-top: 8px; }
            body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item .leaderboard-item-detail .rating {
              margin-top: 4px; }
            body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item td {
              vertical-align: top;
              padding: 16px 0;
              display: flex;
              flex-direction: row; }
              body .app-container .app .page-leaderboards .leaderboards-inner .leaderboard-table .leaderboard-item td > :not(:last-child) {
                margin-right: 8px; }
        body .app-container .app .page-leaderboards .leaderboards-inner .leaderboards-note {
          text-align: center;
          margin-top: 16px;
          color: #777777; }
      body .app-container .app .page-profile .page-profile-inner {
        min-height: 40px;
        position: relative; }
        body .app-container .app .page-profile .page-profile-inner .user-info {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          flex: 1; }
          body .app-container .app .page-profile .page-profile-inner .user-info .user-info-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 16px; }
            body .app-container .app .page-profile .page-profile-inner .user-info .user-info-header .user-info-header-detail > * {
              text-align: center; }
            body .app-container .app .page-profile .page-profile-inner .user-info .user-info-header .user-info-header-detail .user-name {
              word-break: break-word; }
            body .app-container .app .page-profile .page-profile-inner .user-info .user-info-header .user-info-header-detail .joined-date {
              margin: 4px 0;
              color: #525252; }
          body .app-container .app .page-profile .page-profile-inner .user-info .groups-title {
            text-align: center;
            color: #525252; }
          body .app-container .app .page-profile .page-profile-inner .user-info .group-list {
            margin-top: 8px; }
          body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table {
            border-collapse: collapse; }
            body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table th {
              font-size: 12px;
              font-weight: normal;
              border-bottom: 1px solid #c8c8c8; }
            body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table td, body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table th {
              padding: 8px; }
              body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table td.td-template-name, body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table th.td-template-name {
                width: 100%; }
              body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table td.td-template-usages, body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table th.td-template-usages {
                text-align: center; }
            body .app-container .app .page-profile .page-profile-inner .user-info .template-usage-table .template-usage-row:nth-child(2n) {
              background-color: #efefef; }
          body .app-container .app .page-profile .page-profile-inner .user-info .comic-list {
            margin-top: 32px; }
      body .app-container .app .page-how-to-play .how-to-play-image {
        margin: 0 auto;
        border: 1px solid #c8c8c8;
        display: block;
        max-width: 80vw; }
      body .app-container .app .page-how-to-play .demo-pair {
        display: flex;
        justify-content: center;
        flex-direction: row; }
        body .app-container .app .page-how-to-play .demo-pair .comic-panel-pair {
          border: 1px solid #c8c8c8;
          background-color: #fff;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          box-shadow: 6px 6px 6px #00000016;
          -webkit-box-shadow: 6px 6px 6px #00000016;
          -moz-box-shadow: 6px 6px 6px #00000016; }
      body .app-container .app .page-settings .settings-row {
        min-height: 40px;
        position: relative;
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        body .app-container .app .page-settings .settings-row .avatar-selector {
          box-shadow: 6px 6px 6px #00000016;
          -webkit-box-shadow: 6px 6px 6px #00000016;
          -moz-box-shadow: 6px 6px 6px #00000016;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          background-color: #fff;
          border: 1px solid #c8c8c8;
          padding: 16px;
          position: relative; }
          body .app-container .app .page-settings .settings-row .avatar-selector h2 {
            text-align: center;
            margin-bottom: 8px; }
        body .app-container .app .page-settings .settings-row > h2 {
          margin-bottom: 16px;
          text-align: center; }
      body .app-container .app .page-achievements .global-achievements {
        box-shadow: 6px 6px 6px #00000016;
        -webkit-box-shadow: 6px 6px 6px #00000016;
        -moz-box-shadow: 6px 6px 6px #00000016;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        background-color: #fff;
        border: 1px solid #c8c8c8;
        min-height: 40px;
        position: relative;
        padding: 16px; }
        body .app-container .app .page-achievements .global-achievements .global-achievements-inner {
          max-width: 420px;
          margin: 0 auto; }
          body .app-container .app .page-achievements .global-achievements .global-achievements-inner .global-achievement {
            display: flex;
            flex-direction: row; }
            body .app-container .app .page-achievements .global-achievements .global-achievements-inner .global-achievement:not(:last-child) {
              margin-bottom: 16px; }
            body .app-container .app .page-achievements .global-achievements .global-achievements-inner .global-achievement .global-achievement-detail {
              flex: 1;
              margin-left: 8px;
              display: flex;
              flex-direction: column; }
              body .app-container .app .page-achievements .global-achievements .global-achievements-inner .global-achievement .global-achievement-detail .achievement-name {
                font-weight: bold; }
              body .app-container .app .page-achievements .global-achievements .global-achievements-inner .global-achievement .global-achievement-detail .achievement-description {
                margin: 4px 0; }
              body .app-container .app .page-achievements .global-achievements .global-achievements-inner .global-achievement .global-achievement-detail .progress-bar .progress-bar-inner {
                background-color: #ff0052; }
      body .app-container .app .news-message {
        background: #fff;
        color: #080808;
        padding: 4px 0;
        box-shadow: 0px 2px 4px #00000022;
        -webkit-box-shadow: 0px 2px 4px #00000022;
        -moz-box-shadow: 0px 2px 4px #00000022;
        position: relative;
        z-index: 1; }
      body .app-container .app .app-inner {
        display: flex;
        flex-direction: column;
        min-height: 100%; }
        body .app-container .app .app-inner [class^="page-"] {
          flex: 1;
          display: flex;
          flex-direction: column;
          position: relative; }
          body .app-container .app .app-inner [class^="page-"] .page-title + * {
            margin-top: 16px; }
          body .app-container .app .app-inner [class^="page-"] .page-subtitle {
            display: inline-block;
            color: #777777;
            font-size: 12px;
            margin-top: 8px;
            text-align: center; }
            @media (min-width: 768px) {
              body .app-container .app .app-inner [class^="page-"] .page-subtitle {
                font-size: 16px;
                margin-top: 16px;
                text-align: left; } }
          body .app-container .app .app-inner [class^="page-"] > :last-child {
            flex: 1; }
        body .app-container .app .app-inner [class^="panel-"] {
          padding: 32px 0; }
          body .app-container .app .app-inner [class^="panel-"]:first-child {
            padding-top: 16px; }
            @media (min-width: 768px) {
              body .app-container .app .app-inner [class^="panel-"]:first-child {
                padding-top: 32px; } }
          body .app-container .app .app-inner [class^="panel-"].panel-standard {
            background-color: #efefef; }
          body .app-container .app .app-inner [class^="panel-"].panel-inset {
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;
            background-color: #fff;
            box-shadow: inset 0 0 8px #00000044;
            -webkit-box-shadow: inset 0 0 8px #00000044;
            -moz-box-shadow: inset 0 0 8px #00000044; }
        body .app-container .app .app-inner hr {
          width: 100%;
          border: none;
          border-bottom: 1px solid #c8c8c8;
          margin: 32px 0; }
          @media (min-width: 576px) {
            body .app-container .app .app-inner hr {
              margin: 32px 0; } }
      body .app-container .app .container {
        position: relative;
        margin: 0 auto;
        max-width: 648px; }
        body .app-container .app .container .row {
          position: relative;
          padding: 0 16px; }
    body .app-container .loader {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 5; }
      body .app-container .loader.masked {
        background-color: #00000044; }
      body .app-container .loader.image-loader > img {
        position: absolute;
        animation: loader-spin 1.6s linear infinite;
        left: 50%;
        top: calc(50% - 48px);
        width: 48px;
        height: 48px;
        margin-top: -24px;
        margin-left: -24px; }
      body .app-container .loader:not(.image-loader):before {
        content: '';
        position: absolute;
        border: 4px solid transparent;
        border-top: 4px solid #ff0052;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin-top: -16px;
        margin-left: -16px;
        left: 50%;
        top: 50%;
        animation: loader-spin 0.3s linear infinite; }
    body .app-container .hidden {
      display: none !important; }
    body .app-container .disabled {
      opacity: 0.2;
      pointer-events: none; }
    body .app-container .invisible {
      opacity: 0; }
    body .app-container .no-select {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    body .app-container .flex-spacer {
      flex: 1; }
    body .app-container .button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center; }
      body .app-container .button-container.justify-start {
        justify-content: flex-start; }
      body .app-container .button-container.justify-end {
        justify-content: flex-end; }
      body .app-container .button-container > *:not(:first-child) {
        margin-left: 8px; }
      body .app-container .button-container.direction-column {
        flex-direction: column; }
        body .app-container .button-container.direction-column > *:not(:first-child) {
          margin-left: 0;
          margin-top: 8px; }
    body .app-container .comic-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center; }
    body .app-container .modal-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      position: fixed;
      background-color: #00000088;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 20; }
    body .app-container h1, body .app-container h2, body .app-container h3, body .app-container h4, body .app-container h5, body .app-container h6 {
      margin: 0;
      overflow-wrap: break-word;
      text-align: center; }
      @media (min-width: 768px) {
        body .app-container h1, body .app-container h2, body .app-container h3, body .app-container h4, body .app-container h5, body .app-container h6 {
          text-align: left; } }
    body .app-container h3 {
      font-size: 14px;
      color: #525252; }
    body .app-container h5 {
      text-transform: uppercase; }
    body .app-container p, body .app-container ul, body .app-container ol {
      margin: 16px 0; }
      body .app-container p.sm, body .app-container ul.sm, body .app-container ol.sm {
        margin: 8px 0;
        font-size: 12px; }
      body .app-container p.empty-text, body .app-container ul.empty-text, body .app-container ol.empty-text {
        font-size: 14px;
        text-align: center;
        color: #777777; }
      body .app-container p:first-child, body .app-container ul:first-child, body .app-container ol:first-child {
        margin-top: 0; }
      body .app-container p:last-child, body .app-container ul:last-child, body .app-container ol:last-child {
        margin-bottom: 0; }
      body .app-container p.center, body .app-container ul.center, body .app-container ol.center {
        text-align: center; }
    body .app-container span.pink, body .app-container p.pink {
      color: #ff0052; }
    body .app-container a, body .app-container .faux-link {
      text-decoration: none; }
      body .app-container a:not(.link-no-style), body .app-container .faux-link:not(.link-no-style) {
        color: #ff0052;
        cursor: pointer; }
        body .app-container a:not(.link-no-style):hover, body .app-container .faux-link:not(.link-no-style):hover {
          text-decoration: underline; }
    body .app-container input:not([type=checkbox]):not([type=radio]):not([type=file]) {
      background-color: #fff;
      padding: 8px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      min-width: 0;
      border: 1px solid #c8c8c8;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
      body .app-container input:not([type=checkbox]):not([type=radio]):not([type=file]):focus {
        border-color: #969696; }
    body .app-container textarea, body .app-container select {
      background-color: #fff;
      padding: 8px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      font-size: 16px;
      font-family: Arial, Helvetica, sans-serif;
      min-width: 0;
      border: 1px solid #c8c8c8;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none; }
      body .app-container textarea:focus, body .app-container select:focus {
        border-color: #969696; }
    body .app-container .sorry-panel {
      margin: 0 auto;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 32px; }
      body .app-container .sorry-panel h1, body .app-container .sorry-panel h2, body .app-container .sorry-panel h3, body .app-container .sorry-panel h4, body .app-container .sorry-panel h5, body .app-container .sorry-panel h6 {
        text-align: center; }
    body .app-container .comic-panel {
      height: calc(450px * 0.48);
      width: calc(540px * 0.48); }
      body .app-container .comic-panel .dialogue > * {
        line-height: calc(40px * 0.48);
        font-size: calc(36px * 0.48); }
      body .app-container .comic-panel .comic-panel-subtle {
        line-height: calc(18px * 0.48);
        font-size: calc(16px * 0.48); }
    body .app-container .comic-width {
      width: calc(540px * 0.48); }

@media screen and (orientation: landscape) and (min-width: 576px) {
  body .app-container .comic-width {
    width: calc(540px * 0.96); } }

@media screen and (orientation: portrait) and (min-width: 768px) {
  body .app-container .comic-width {
    width: calc(540px * 0.96); } }
    body .app-container .comic-panel-width {
      width: calc(540px * 0.48); }
    body .app-container .comic-panel-height {
      height: calc(450px * 0.48); }
    @media (min-width: 360px) {
      body .app-container .comic-panel {
        height: calc(450px * 0.52);
        width: calc(540px * 0.52); }
        body .app-container .comic-panel .dialogue > * {
          line-height: calc(40px * 0.52);
          font-size: calc(36px * 0.52); }
        body .app-container .comic-panel .comic-panel-subtle {
          line-height: calc(18px * 0.52);
          font-size: calc(16px * 0.52); }
      body .app-container .comic-width {
        width: calc(540px * 0.52); } }

@media screen and (min-width: 360px) and (orientation: landscape) and (min-width: 576px) {
  body .app-container .comic-width {
    width: calc(540px * 1.04); } }

@media screen and (min-width: 360px) and (orientation: portrait) and (min-width: 768px) {
  body .app-container .comic-width {
    width: calc(540px * 1.04); } }
    @media (min-width: 360px) {
      body .app-container .comic-panel-width {
        width: calc(540px * 0.52); }
      body .app-container .comic-panel-height {
        height: calc(450px * 0.52); } }
    @media (min-width: 700px) {
      body .app-container .comic-panel {
        height: calc(450px * 0.56);
        width: calc(540px * 0.56); }
        body .app-container .comic-panel .dialogue > * {
          line-height: calc(40px * 0.56);
          font-size: calc(36px * 0.56); }
        body .app-container .comic-panel .comic-panel-subtle {
          line-height: calc(18px * 0.56);
          font-size: calc(16px * 0.56); }
      body .app-container .comic-width {
        width: calc(540px * 0.56); } }

@media screen and (min-width: 700px) and (orientation: landscape) and (min-width: 576px) {
  body .app-container .comic-width {
    width: calc(540px * 1.12); } }

@media screen and (min-width: 700px) and (orientation: portrait) and (min-width: 768px) {
  body .app-container .comic-width {
    width: calc(540px * 1.12); } }
    @media (min-width: 700px) {
      body .app-container .comic-panel-width {
        width: calc(540px * 0.56); }
      body .app-container .comic-panel-height {
        height: calc(450px * 0.56); } }
    @media screen and (orientation: landscape) {
      body .app-container .comic-panel {
        height: calc(450px * 0.48);
        width: calc(540px * 0.48); }
        body .app-container .comic-panel .dialogue > * {
          line-height: calc(40px * 0.48);
          font-size: calc(36px * 0.48); }
        body .app-container .comic-panel .comic-panel-subtle {
          line-height: calc(18px * 0.48);
          font-size: calc(16px * 0.48); }
      body .app-container .comic-width {
        width: calc(540px * 0.48); } }

@media screen and (orientation: landscape) and (orientation: landscape) and (min-width: 576px) {
  body .app-container .comic-width {
    width: calc(540px * 0.96); } }

@media screen and (orientation: landscape) and (orientation: portrait) and (min-width: 768px) {
  body .app-container .comic-width {
    width: calc(540px * 0.96); } }
    @media screen and (orientation: landscape) {
      body .app-container .comic-panel-width {
        width: calc(540px * 0.48); }
      body .app-container .comic-panel-height {
        height: calc(450px * 0.48); } }

@media screen and (orientation: landscape) and (min-height: 680px) {
  body .app-container .comic-panel {
    height: calc(450px * 0.52);
    width: calc(540px * 0.52); }
    body .app-container .comic-panel .dialogue > * {
      line-height: calc(40px * 0.52);
      font-size: calc(36px * 0.52); }
    body .app-container .comic-panel .comic-panel-subtle {
      line-height: calc(18px * 0.52);
      font-size: calc(16px * 0.52); }
  body .app-container .comic-width {
    width: calc(540px * 0.52); } }

@media screen and (orientation: landscape) and (min-height: 680px) and (orientation: landscape) and (min-width: 576px) {
  body .app-container .comic-width {
    width: calc(540px * 1.04); } }

@media screen and (orientation: landscape) and (min-height: 680px) and (orientation: portrait) and (min-width: 768px) {
  body .app-container .comic-width {
    width: calc(540px * 1.04); } }

@media screen and (orientation: landscape) and (min-height: 680px) {
  body .app-container .comic-panel-width {
    width: calc(540px * 0.52); }
  body .app-container .comic-panel-height {
    height: calc(450px * 0.52); } }

@media screen and (orientation: landscape) and (min-height: 800px) {
  body .app-container .comic-panel {
    height: calc(450px * 0.56);
    width: calc(540px * 0.56); }
    body .app-container .comic-panel .dialogue > * {
      line-height: calc(40px * 0.56);
      font-size: calc(36px * 0.56); }
    body .app-container .comic-panel .comic-panel-subtle {
      line-height: calc(18px * 0.56);
      font-size: calc(16px * 0.56); }
  body .app-container .comic-width {
    width: calc(540px * 0.56); } }

@media screen and (orientation: landscape) and (min-height: 800px) and (orientation: landscape) and (min-width: 576px) {
  body .app-container .comic-width {
    width: calc(540px * 1.12); } }

@media screen and (orientation: landscape) and (min-height: 800px) and (orientation: portrait) and (min-width: 768px) {
  body .app-container .comic-width {
    width: calc(540px * 1.12); } }

@media screen and (orientation: landscape) and (min-height: 800px) {
  body .app-container .comic-panel-width {
    width: calc(540px * 0.56); }
  body .app-container .comic-panel-height {
    height: calc(450px * 0.56); } }
